
.goods-master-video {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 20px;
    .goods-video-upload-box {
        .upload-process-line {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 5px;
            background: #68C23A;
          border-radius: 2px;
        }
        position: relative;
        width: 100px;
        height: 100%;
        border: 1px dashed #DBDBDB;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-text-content {
            font-size: 14px;
            color: #717171;
            p {
                text-align: center;
                i {
                  font-size: 20px;
                }
            }
        }
        .master-up-btn {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 999;
            opacity: 0;
            cursor: pointer;
        }
    }
    .video-preview-box {
        border: none;
        flex-direction: column;
        .delete-btn {
            cursor: pointer;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;
            opacity: .6;
            i {
              color: #F56C6C;
            }
        }
        .preview-img {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: auto;
            }
        }
        .play-font-btn {
            cursor: pointer;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
            background: #1a1a1a;
            opacity: .6;
            span {
                font-size: 14px;
                color: #fff;
            }
        }
    }
}
/deep/.w-e-toolbar{
    height: 60px;
    line-height: 50px;
}
/deep/.w-e-text-container{
    height: 500px!important;
}
.newLine{
    display: flex;
}
.showPicture{
    margin-top: 20px;
    padding: 20px;
    background: #F5F7F9;
    //margin-left: 100px;
    .imgBox{
        padding-top: 20px;
        display: flex;
        .imgBox-item{
            width: 70px;
            height: 70px;
            /*overflow: hidden;*/
            border: 1px solid #E5E5E5;
            position: relative;
            margin-left: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            i{
                position: absolute;
                right: -5px;
                top: -5px;
                color: #d81e06;
                font-size: 12px;
                cursor: pointer;
            }
            img{
                max-height: 100%;
                max-width: 100%;
            }
        }
        .imgBox-item:nth-child(1){
            margin-left: 0;
        }
    }
    .showPictureUpload{
        .uploadBtn{
            margin-top: 20px;
            cursor: pointer;
            width: 71px;
            height: 71px;
            border: 1px solid #E5E5E5;
            background: #FFFFFF;
            border-radius: 2px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            span{
                color: #999999;
                font-size: 12px;
                font-weight: 500;
            }
            i{
                font-size: 18px;
                color: #BFBFBF;
            }
        }
        .hint{
            display: block;
            margin-top: 20px;
            color: #999999;
            font-size: 14px;
            font-weight: 500;
        }
    }
}
.storeGoodsAdd{
    padding: 20px 26px;
    background: #ffffff;
    .storeGoodsAdd-title{
        color: #333333;
        font-weight: bold;
        font-size: 16px;
    }
    .storeGoodsAdd-middle{
        .storeGoodsAdd-middle-span{
            padding-top: 18px;
            color: #333333;
            font-weight: bold;
            font-size: 14px;
            span:nth-child(1){
                width: 3px;
                height: 10px;
                background: #FF4D77;
                display: inline-block;
            }
            span:nth-child(2){
                margin-left: 10px;
            }
            .storeGoodsAdd-form{
                margin-top: 20px;
            }
        }

    }
}
.footer{
    span{
        width: 94px;
        height: 40px;
        background: #FD4446;
        display: block;
        color: #FFFFFF;
        text-align: center;
        line-height: 40px;
        border-radius: 2px;
        margin: 20px auto;
        cursor: pointer;
    }
}
